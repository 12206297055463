import React, { useEffect } from 'react';
import * as Yup from "yup";
import geoip2 from 'geoip2';
import BootstrapField from '../BootstrapField';

import WizardStep from '../WizardStep';


const ZipCode = props => {
	const {
		setValidationSchema,
		getFieldError,
		values,
		setFieldValue,
		onButtonClick
	} = props;

	const validationSchema = Yup.object({
        zip_code: Yup.string()
        .matches(/^[0-9]{5}$/, "A Valid Zip Code is required.")
        .required("A Valid Zip Code is required."),
    });

    const getZipCode = () => {
    	const onSuccess = (geoipResponse) => {
    		const { postal: { code: zipCode } } = geoipResponse;

    		if (!values.zip_code) {
    			setFieldValue('zip_code', zipCode);
    		}
    	};

    	const onError = (errors) => {
    		return;
    	}

    	geoip2.city(onSuccess, onError);
    };

	useEffect(() => {
  		setValidationSchema(validationSchema);

  		getZipCode();


  	}, []);

	return (
		<WizardStep>
			<div className="question row">
				<div className="col-12 col-lg-6">
					<h3>What Is Your Zip Code?</h3>
					<label htmlFor="zip_code">Zip Code</label>
					<div className="input-group">
						<BootstrapField
							type="text"
							className="form-control custom-form-control"
							name="zip_code"
							max="5" min="5"
							placeholder="#####"
							hasErrors={getFieldError('zip_code')}
						/>
						<div className="input-group-append">
							<button
								type="submit"
								className="btn btn-success"
								onClick={() => onButtonClick('zip_code', values['zip_code'])}
								>
									Continue
								</button>
						</div>
						<div className="invalid-feedback">{ getFieldError('zip_code') }</div>
				   	</div>
				</div>
			</div>
		</WizardStep>
	);
};

export default ZipCode;
