import React, { useEffect, useRef } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as Yup from "yup";
import { connect } from 'react-redux';

import WizardStep from '../WizardStep';
import BootstrapField from '../BootstrapField';
import PhoneField from '../PhoneField';
import { getCityFromZip, getTcpa } from '../../data/formEntry/actions';

const GetMyQuote = props => {
	const {
		setValidationSchema,
		getFieldError,
		setFieldValue,
		touched,
		errors,
		formEntry,
		getCity,
		jornayaRef,
		getTcpa
	} = props;

	const addressFieldRef = useRef(null);


	const validationSchema = Yup.object({
		first_name: Yup.string().required('First Name is required.'),
        last_name: Yup.string().required('Last Name is required.'),
        email: Yup.string()
        	.email('A valid Email Address is required.')
        	.required('A valid Email Address is required.'),
        address: Yup.string().required('Street Address is required.'),
        phone: Yup.string().required('A valid Phone Number is required.')
        		.test(
        			'is-phone-number',
        			'A valid Phone Number is required.',
        			(value) => isPossiblePhoneNumber(value)
        		)
    });

    useEffect(() => {
		const trustedFormCertId = document.getElementById('xxTrustedFormCertUrl_0');

  		setValidationSchema(validationSchema);

		if (!formEntry.tcpa) {
			getTcpa();
		}

  		if (formEntry['zip_code']) {
			getCity(formEntry['zip_code']);
		}

  		if (trustedFormCertId) {
  			setFieldValue('trusted_form_cert_id', trustedFormCertId.value);
		}


  	}, []);

    useEffect(() => {
    	if (!formEntry['city'] && formEntry['zip_code']) {
			getCity(formEntry['zip_code']);
		}

    	// if (formEntry['zip_code']) {
    	// 	const genders = {
    	// 		'Male': 'M',
		// 		'Female': 'F',
		// 		'Non-Binary': 'X',
		// 	}
    	// 	const currentlyIns = formEntry['medicare_status'] === 'Enrolled' ? 1 : 0;
    	// 	const gender = genders[formEntry.gender]
    	// 	const dob = `${formEntry.dob.year}-${formEntry.dob.month}-${formEntry.dob.day}`;
		//
		// 	window.loadAds(
		// 		formEntry.zip_code,
		// 		formEntry.affid,
		// 		formEntry.efTid,
		// 		gender,
		// 		dob,
		// 		currentlyIns
		// 	);
		// }

	}, [formEntry['zip_code']]);


    useEffect( () => {
		const setCityState = async () => {
			// Set State and City on change
			await Promise.all([
				setFieldValue('state', formEntry.state),
				setFieldValue('city', formEntry.city)
			]);
		}

		setCityState();

	}, [formEntry.state]);


  	const onClick = async () => {
  		// console.log(jornayaRef.current.value);
  		await setFieldValue('jornaya_lead_id', jornayaRef.current.value);
  	};

  	const onAddressFocus = () => {
		addressFieldRef.current.scrollIntoView()
	}

	return (
		<WizardStep>
			<div className="question row">
				<div className="col-12 col-md-8 col-lg-6">
					<div className="row">
						<div className="col-12 col-sm-6 mb-2">
							<h3>First Name</h3>
							<div className="input-group">
								<BootstrapField
									type="text"
									className="form-control"
									name="first_name"
									hasErrors={getFieldError('first_name')}
								/>
								<div className="invalid-feedback">{ getFieldError('first_name') }</div>
						   	</div>
						</div>
						<div className="col-12 col-sm-6 mb-2">
							<h3>Last Name</h3>
							<div className="input-group">
								<BootstrapField
									type="text"
									className="form-control"
									name="last_name"
									hasErrors={getFieldError('last_name')}
								/>
								<div className="invalid-feedback">{ getFieldError('last_name') }</div>
						   	</div>
						</div>
						<div className="col-12 col-sm-6 mb-2">
							<h3>Email Address</h3>
							<div className="input-group">
								<BootstrapField
									type="email"
									className="form-control"
									name="email"
									hasErrors={getFieldError('email')}
								/>
								<div className="invalid-feedback">{ getFieldError('email') }</div>
						   	</div>
						</div>
						<div className="col-12 col-sm-6 mb-2">
							<h3>Phone Number</h3>
							<div className="input-group">
								<BootstrapField
									className="form-control"
									name="phone"
									hasErrors={getFieldError('phone')}
									component={PhoneField}
								/>
								{ errors['phone'] && touched['phone']
									&& <div className="invalid-feedback show">A valid Phone Number is required.</div>
								}
						   	</div>
						</div>
						<div className="col-12 col-sm-6" ref={addressFieldRef}>
							<h3>Street Address</h3>
							<div className="input-group">
								<BootstrapField
									type="text"
									className="form-control"
									name="address"
									hasErrors={getFieldError('address')}
									onFocus={onAddressFocus}
								/>
								<div className="invalid-feedback">Street Address is required.</div>
						   	</div>
							<br />
							<h6 className="text-info">
								{formEntry.city}, {formEntry.state} {formEntry.zip_code}
							</h6>
						</div>
					</div>
					<div className="row">
						<div className="col mt-3">
							<div className="alert alert-info">
								By continuing I agree to the terms below the yellow button.
							</div>
							<button
								onClick={() => onClick()}
								type="submit"
								className="mt-2 btn btn-success btn-lg"
							>
								Get My Quotes
							</button>
							<div className="small mt-4">
								<label><input type="hidden" id="leadid_tcpa_disclosure"/>

									<div dangerouslySetInnerHTML={{__html: formEntry.tcpa}}/>

								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</WizardStep>
	);
};

const mapStateToProps = state => {
	const { formEntry, additionalProviders } = state.data;

	return {
		formEntry,
		additionalProviders
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getCity: (zipcode) => dispatch(getCityFromZip(zipcode)),
		getTcpa: () => dispatch(getTcpa())
		// requestAdditionalProviders: () => dispatch(requestAdditionalProviders()),
		// receiveAdditionalProviders: data => dispatch(receiveAdditionalProviders(data)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GetMyQuote);
