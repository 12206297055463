import React, {Fragment, useEffect, useState, useLayoutEffect} from 'react';
import { connect } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';
import EF from 'EF';
import queryString from 'querystring';

import Failed from './Failed';
import Success from './Success';
import { setEftId } from "../../data/formEntry/actions";
import Loading from '../../components/Loading';


const Results = props => {
	const {
		formEntry,
	} = props;

	// const [everflowSent, setEveflowSent] = useState(false);

	useEffect(() => {
		ReactPixel.trackCustom('form_submit');

		window.snaptr('track','PURCHASE');

		const ads = document.getElementById('mediaAlphaPortal');

		if (ads) {
			ads.style.display = "block";
		}

	}, []);

	// useEffect(() => {
	// 	if( response.price && !everflowSent) {
	// 		EF.conversion({
	// 			offer_id: formEntry.oid ? parseInt(formEntry.oid) : 39009,
	// 			affiliate_id: formEntry.affid && parseInt(formEntry.affid),
	// 			amount: response.price,
	// 			adv1: formEntry.first_name,
	// 			adv2: formEntry.last_name,
	// 			adv3: formEntry.email,
	// 			adv4: response.lead_id
	// 		});
	//
	// 		setEveflowSent(true);
	// 	}
	// }, [response.price]);

	useEffect(() => {
		if (formEntry.complete === true) {
			const data = {
				aff_id: formEntry.affid,
				eftid: formEntry.efTid,
				oid: formEntry.oid,
				trusted_form_cert_id: formEntry.trusted_form_cert_id,
				sub1: formEntry.lp_s1,
				sub2: formEntry.lp_s2,
				sub3: formEntry.lp_s3,
				sub4: formEntry.lp_s4,
				sub5: formEntry.lp_s5,
				source_id: formEntry.source_id,
				sessionId: formEntry.sessionId,
				insured: formEntry.medicare_status,
				dobm: formEntry.dob.month,
				dobd: formEntry.dob.day,
				doby: formEntry.dob.year,
				gender: formEntry.gender,
				zip: formEntry.zip_code,
				fname: formEntry.first_name,
				lname: formEntry.last_name,
				email: formEntry.email,
				phone: formEntry.phone,
				street: formEntry.address,
				town: formEntry.city,
				state: formEntry.state,
				country: formEntry.country,
				ip_address: formEntry.ip_address,
				jornaya_lead_id: formEntry.jornaya_lead_id
			}

			const dataQueryString = queryString.stringify(data);
			console.log(`redirecting to: https://proquotes.co/medicare/success/1/?${dataQueryString}`);
			window.location.replace(`https://proquotes.co/medicare/success/1/?${dataQueryString}`);
			// window.location.replace(`http://success.medicare:8888/?${dataQueryString}`);
		}

	}, [formEntry.complete]);


	// useLayoutEffect(() => {
	// 	window.loadAds(formEntry.zip_code, adsLoaded);
	// });
	//
	// const adsLoaded = () => {
	// 	const ads = document.getElementById('ads').innerHTML;
	//
	// 	setAds(ads);
	// }

	// const renderResponse = () => {
	// 	if (response.result === "success") {
	// 		return (
	// 			<Success
	// 				price={response.price}
	// 				offerId={formEntry.oid}
	// 				leadId={response.lead_id}
	// 				firstName={formEntry.first_name}
	// 				lastName={formEntry.last_name}
	// 				email={formEntry.email}
	// 			/>
	// 		);
	// 	} else if (response.result === "failed") {
	// 		return (
	// 			<Failed
	// 				offerId={formEntry.oid}
	// 			/>
	// 		);
	// 	} else {
	// 		return null;
	// 	}
	// }

	return (
		<Loading />
	);
};

const mapStateToProps = state => {
	const { formEntry, additionalProviders } = state.data;

	return {
		formEntry,
	}
}


export default connect(mapStateToProps)(Results);

